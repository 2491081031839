import Image from 'next/legacy/image';
import React from 'react';
import { Box, Flex } from 'shared/grid';
import RenderSlate from 'shared/slate-shared/render';
import { Heading } from 'shared/typography';
import { unType } from 'shared/utils/cms';
import {
  GridContainer,
  nextImageProps,
  Panel,
  SectionHeading,
  SectionSubtitle,
  SectionTagline,
} from '../landing-shared';

const elementOverrides = {
  'heading-one': function headingOne(element, children) {
    return (
      <Heading fontSize={5} as="h1" mb="1em">
        {children}
      </Heading>
    );
  },
  'heading-two': function headingTwo(element, children) {
    return (
      <Heading fontSize={4} as="h2" mb="1em">
        {children}
      </Heading>
    );
  },
  'heading-three': function headingThree(element, children) {
    return (
      <Heading fontSize={4} as="h3" mb="1em">
        {children}
      </Heading>
    );
  },
  // paragraph: function p(element, children) {
  //   return (
  //     <Box as="p" fontSize={3} mb={3}>
  //       {children}
  //     </Box>
  //   );
  // },
  image: function image(element, children) {
    return (
      <Flex justifyContent="center">
        <Box
          width={['100%', `${element.width ?? 100}%`]}
          maxWidth={`min(${element.naturalWidth}px, 100%)`}
          sx={{
            borderRadius: 3,
            boxShadow: 'rgb(16 25 40 / 8%) 0px 16px 32px 0px',
          }}
          mb="1em"
        >
          <Image
            lazyBoundary="600px"
            sizes="550px"
            layout="responsive"
            alt="cta"
            {...nextImageProps(element)}
          />
        </Box>
      </Flex>
    );
  },
};

const RichText = ({ data, ...rest }) => {
  const { title, subtitle, tagline, body, sectionId } = React.useMemo(
    () => unType(data),
    [data]
  );

  return (
    <Panel {...rest} sectionId={sectionId}>
      <GridContainer py={4}>
        {tagline && <SectionTagline>{tagline}</SectionTagline>}
        {title && <SectionHeading>{title}</SectionHeading>}
        {subtitle && <SectionSubtitle>{subtitle}</SectionSubtitle>}

        {body && (
          <Box fontSize={3}>
            <RenderSlate value={body} elementOverrides={elementOverrides} />
          </Box>
        )}
      </GridContainer>
    </Panel>
  );
};

export default RichText;
