import React from 'react';
import { Box, BoxProps } from '../grid';

const arrowCss = {
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  borderStyle: 'solid',
  borderWidth: '0 6px 6px 0',
  display: 'inline-block',
  padding: '6px',
  cursor: 'pointer',
};

type Props = BoxProps;

export const Arrow = React.forwardRef<HTMLDivElement, Props>(function Arrow(
  { color, ...props },
  ref
) {
  return (
    <Box
      ref={ref}
      __css={{
        ...arrowCss,
        borderColor: color,
      }}
      {...props}
    />
  );
});

export const Arrows = Box;

interface ArrowProps {
  color: string;
}

export const ArrowPrev = React.forwardRef<HTMLDivElement, Props>(function Arrow(
  { color, ...props },
  ref
) {
  return (
    <Box
      ref={ref}
      __css={{
        ...arrowCss,
        left: '12px',
        transform: 'rotate(135deg)',
        borderColor: color,
      }}
      {...props}
    />
  );
});

export const ArrowNext = React.forwardRef<HTMLDivElement, Props>(function Arrow(
  { color, ...props },
  ref
) {
  return (
    <Box
      ref={ref}
      __css={{
        ...arrowCss,
        right: '12px',
        transform: 'rotate(-45deg)',
        borderColor: color,
      }}
      {...props}
    />
  );
});

export const Nav = React.forwardRef<HTMLDivElement, Props>(function Arrow(
  { color, ...props },
  ref
) {
  return (
    <Box
      ref={ref}
      __css={{
        position: 'absolute',
        bottom: '12px',
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      {...props}
    />
  );
});

interface NavItemProps {
  color: string;
  active: boolean;
}

export const NavItem = React.forwardRef<
  HTMLDivElement,
  NavItemProps & BoxProps
>(function Arrow({ color, active, ...props }, ref) {
  return (
    <Box
      ref={ref}
      __css={{
        width: '12px',
        height: '12px',
        margin: '3px 6px',
        borderRadius: '50%',
        background: color,
        opacity: active ? 1.0 : 0.5,
        cursor: 'pointer',
      }}
      {...props}
    />
  );
});
