import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import FormError from 'shared/form-error';
import { StyledInput, StyledInputProps } from '../input';
import Label from '../label';

type Props = {
  label?: any;
  error?: string;
} & StyledInputProps &
  React.TextareaHTMLAttributes<HTMLTextAreaElement>;

type RefHandler = (ref: HTMLTextAreaElement | null) => void;

// eslint-disable-next-line react/display-name
const TextArea = React.forwardRef(
  (
    { label, error, id, ...rest }: Props,
    ref: React.Ref<HTMLInputElement> | RefHandler
  ) => {
    const [isFocused, setFocus] = React.useState(false);

    return (
      <>
        {label && (
          <Label
            mb={2}
            htmlFor={id || rest.name}
            isFocused={isFocused}
            invalid={!!error}
          >
            {label}
          </Label>
        )}
        <StyledInput
          as={TextareaAutosize}
          __css={{
            borderRadius: 2,
            minHeight: '70px',
            resize: 'vertical',
          }}
          id={id || rest.name}
          ref={ref as any}
          invalid={!!error}
          onFocus={(e) => {
            rest.onFocus && rest.onFocus(e);
            setFocus(true);
          }}
          onBlur={(e) => {
            rest.onBlur && rest.onBlur(e);
            setFocus(false);
          }}
          {...(rest as any)}
        />
        {error && <FormError>{error}</FormError>}
      </>
    );
  }
);

export default TextArea;
