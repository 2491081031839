import {
  faFacebookSquare,
  faInstagram,
  faTwitterSquare,
  faWhatsapp,
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, Container, Grid } from 'shared/grid';
import Logo from 'shared/images/logo-white-text.svg';
import { Heading, Link } from 'shared/typography';
import { getInEntry } from 'shared/utils/cms';
import { useAppDispatch } from '../../store';
import { ScrollButton } from '../landing-shared';
import ColorMode from 'shared/color-modes';
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'shared/utils/constants';

const FooterMenu = ({ text, links, sx = {}, ...rest }) => {
  return (
    <Box mb={3}>
      <Grid
        gridGap={2}
        sx={{
          ...sx,
          'a, button': {
            fontFamily: 'heading',
            fontWeight: 'heading',
          },
          button: {
            textAlign: 'left',
          },
        }}
      >
        {text && (
          <Heading fontWeight="bold" fontSize={2} mb={2} color="brand0.7">
            {text}
          </Heading>
        )}
        {links.map((v, idx) => {
          if (v.href[0] === '#') {
            return (
              <ScrollButton
                key={idx}
                activeClass="active"
                to={v.href.substring(1)}
                spy={true}
                smooth={true}
                duration={500}
              >
                {v.text}
              </ScrollButton>
            );
          } else if (v.href[0] === '/') {
            return (
              <Link as={NextLink} key={idx} href={v.href}>
                {v.text}
              </Link>
            );
          } else {
            return (
              <Link key={idx} href={v.href} target="_blank" as="a">
                {v.text}
              </Link>
            );
          }
        })}
      </Grid>
    </Box>
  );
};

const Footer = ({
  isHome = false,
  links = EMPTY_ARRAY,
  settingsData = EMPTY_OBJECT,
}) => {
  const dispatch = useAppDispatch();
  const { locale: language, asPath } = useRouter();

  const emailAddress = getInEntry(
    'translations.emailAddress',
    settingsData,
    language
  );
  const phoneNumber = getInEntry(
    'translations.phoneNumber',
    settingsData,
    language
  );
  const whatsappNumber = getInEntry(
    'translations.whatsappNumber',
    settingsData,
    language
  );
  const facebookUsername = getInEntry(
    'translations.facebookUsername',
    settingsData,
    language
  );
  const twitterHandle = getInEntry(
    'translations.twitterHandle',
    settingsData,
    language
  );
  const instagramUsername = getInEntry(
    'translations.instagramUsername',
    settingsData,
    language
  );

  return (
    <ColorMode mode="dark">
      <Box py={3} as="footer" bg="brand0.1" color="text">
        <Container px={3}>
          <Grid
            gridTemplateColumns={['1fr', '1fr', '1fr 2fr 1fr']}
            py={4}
            gridGap={4}
          >
            <Box>
              <Logo width={170} alt="Bookem logo" title="Bookem logo" />
            </Box>
            <Grid gridTemplateColumns={['1fr', '1fr 1fr']}>
              {
                // [...links, ...links]
                // .filter((_, idx) => idx === 0)
                links.map(({ text, links }, idx) => (
                  <FooterMenu key={idx} text={text} links={links} />
                ))
              }
            </Grid>
            <Box>
              <Grid gridGap={2} fontWeight="heading">
                {emailAddress && (
                  <Box>
                    <Link href={`mailto:${emailAddress}?subject=Help`}>
                      <Box display="inline-block" mr={2}>
                        <FontAwesomeIcon
                          fixedWidth
                          size="lg"
                          icon={faEnvelope}
                        />
                      </Box>
                      {emailAddress}
                    </Link>
                  </Box>
                )}
                {phoneNumber && (
                  <Box>
                    <Link href={`tel:${phoneNumber}`}>
                      <Box display="inline-block" mr={2}>
                        <FontAwesomeIcon fixedWidth size="lg" icon={faPhone} />
                      </Box>
                      {(phoneNumber as string).substring(0, 3)}{' '}
                      {(phoneNumber as string).substring(3, 5)}{' '}
                      {(phoneNumber as string).substring(5, 8)}{' '}
                      {(phoneNumber as string).substring(8)}
                    </Link>
                  </Box>
                )}
                {whatsappNumber && (
                  <Box>
                    <Link
                      href={`https://api.whatsapp.com/send?phone=${whatsappNumber}`}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <Box display="inline-block" mr={2}>
                        <FontAwesomeIcon
                          fixedWidth
                          size="lg"
                          icon={faWhatsapp}
                        />
                      </Box>
                      {(whatsappNumber as string).substring(0, 3)}{' '}
                      {(whatsappNumber as string).substring(3, 5)}{' '}
                      {(whatsappNumber as string).substring(5, 8)}{' '}
                      {(whatsappNumber as string).substring(8)}
                    </Link>
                  </Box>
                )}
                {facebookUsername && (
                  <Box>
                    <Link
                      href={`https://www.facebook.com/${facebookUsername}`}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <Box display="inline-block" mr={2}>
                        <FontAwesomeIcon
                          fixedWidth
                          size="lg"
                          icon={faFacebookSquare}
                        />
                      </Box>
                      Facebook
                    </Link>
                  </Box>
                )}
                {twitterHandle && (
                  <Box>
                    <Link
                      href={`https://twitter.com/${twitterHandle}`}
                      target="_blank"
                      rel="noreferrer noopener"

                      // lineHeight="1.8em"
                    >
                      <Box display="inline-block" mr={2}>
                        <FontAwesomeIcon
                          fixedWidth
                          size="lg"
                          icon={faTwitterSquare}
                        />
                      </Box>
                      Twitter
                    </Link>
                  </Box>
                )}
                {instagramUsername && (
                  <Box>
                    <Link
                      href={`https://instagram.com/${instagramUsername}`}
                      target="_blank"
                      rel="noreferrer noopener"

                      // lineHeight="1.8em"
                    >
                      <Box display="inline-block" mr={2}>
                        <FontAwesomeIcon
                          fixedWidth
                          size="lg"
                          icon={faInstagram}
                        />
                      </Box>
                      Instagram
                    </Link>
                  </Box>
                )}
              </Grid>
            </Box>
          </Grid>
          <Box>
            <FormattedMessage
              id="LandingPage.copyright"
              defaultMessage="Copyright © Bookem. All rights reserved."
            />
            &nbsp;
            <Link
              as={NextLink}
              sx={{ textDecoration: 'underline' }}
              href="/terms-of-service"
            >
              <FormattedMessage
                id="LandingPage.termsOfServiceLink"
                defaultMessage="Terms of service"
              />
            </Link>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <Link
              as={NextLink}
              sx={{ textDecoration: 'underline' }}
              href="/privacy-policy"
            >
              <FormattedMessage
                id="LandingPage.privacyPolicyLink"
                defaultMessage="Privacy policy"
              />
            </Link>
          </Box>
        </Container>
      </Box>
    </ColorMode>
  );
};

export default Footer;
