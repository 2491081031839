import { Theme, ThemeContext } from '@emotion/react';
import React from 'react';
import SVG from 'react-inlinesvg';
import { Box, Flex, Grid } from 'shared/grid';
import { Heading } from 'shared/typography';
import { unType } from 'shared/utils/cms';
import {
  GridContainer,
  Panel,
  SectionHeading,
  SectionSubtitle,
  SectionTagline,
  Paragraphs,
} from '../landing-shared';

const IconSummary = ({ icon, title, description }) => {
  const theme = React.useContext<Theme>(ThemeContext as any);
  return (
    <Grid textAlign="left" gridTemplateColumns={'auto 1fr'} gridGap={4}>
      <Flex>
        {/* @ts-ignore */}
        <SVG
          src={`/images/icons/${icon}.svg`}
          className="fa-3x fa-fw svg-inline--fa"
          style={
            {
              // '--fa-primary-color': theme.colors.brand1[4],
              // '--fa-secondary-color': theme.colors.brand1[4],
              // '--fa-primary-opacity': 1,
              // '--fa-secondary-opacity': 0.6,
            } as any
          }
        />
      </Flex>

      <Grid gridGap={2} gridTemplateRows="auto 1fr">
        <Heading as="h3" fontSize={[2, 3]} fontWeight="heading">
          {title}
        </Heading>

        <Paragraphs text={description} sx={{ overflowWrap: 'break-word' }} />
      </Grid>
    </Grid>
  );
};

const IconSummarySection = ({ data, ...rest }) => {
  const { title, subtitle, tagline, background, decoration, items, sectionId } =
    React.useMemo(() => unType(data), [data]);

  return (
    <Panel {...rest} sectionId={sectionId}>
      <GridContainer>
        <Grid>
          {tagline && <SectionTagline>{tagline}</SectionTagline>}
          <SectionHeading>{title}</SectionHeading>
          {subtitle && <SectionSubtitle>{subtitle}</SectionSubtitle>}
        </Grid>

        <Grid
          gridGap={4}
          gridTemplateColumns={['1fr', '1fr 1fr', '1fr 1fr 1fr']}
        >
          {items.map((props, idx) => (
            <IconSummary key={idx} {...props} />
          ))}
        </Grid>
      </GridContainer>
    </Panel>
  );
};

export default IconSummarySection;
