import { faEnvelope } from '@fortawesome/pro-light-svg-icons';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { isValidNumber } from 'libphonenumber-js';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import isEmail from 'shared/utils/is-email';
import Button from 'shared/button';
import FormError from 'shared/form-error';
import { Box, Flex, Grid } from 'shared/grid';
import Input from 'shared/input';
import Label from 'shared/label';
import PhoneInput from 'shared/phone-input';
import axios from 'shared/anaxios';
import settings from 'shared/settings';
import Textarea from 'shared/textarea';
import { unType } from 'shared/utils/cms';
import { getScrollToErrors } from 'shared/utils/form';
import {
  GridContainer,
  Panel,
  SectionHeading,
  SectionSubtitle,
  SectionTagline,
} from '../landing-shared';
import { useLibPhoneNumber } from 'shared/hooks/phone-number';

export const ContactForm = ({ data, ...rest }) => {
  const { title, subtitle, tagline, sectionId } = React.useMemo(
    () => unType(data),
    [data]
  );
  const [isSent, setIsSent] = React.useState(false);
  const decorators = React.useMemo(() => [getScrollToErrors()], []);
  const { isValidNumber } = useLibPhoneNumber();

  return (
    <>
      <Panel {...rest} sectionId={sectionId}>
        <GridContainer>
          <Grid>
            {tagline && <SectionTagline>{tagline}</SectionTagline>}
            <SectionHeading>{title}</SectionHeading>
            {subtitle && <SectionSubtitle>{subtitle}</SectionSubtitle>}
          </Grid>
          <Grid gridGap={4}>
            {!isSent ? (
              <Flex justifyContent="center">
                <Form
                  decorators={decorators}
                  onSubmit={(values: any) => {
                    return axios
                      .post(`${settings.api2Root}/contact`, {
                        ...values,
                        subject: values.email,
                      })
                      .then(() => setIsSent(true));
                  }}
                >
                  {({ handleSubmit, submitting }) => (
                    <Grid
                      flexBasis="700px"
                      gridGap={3}
                      gridTemplateColumns={['1fr', '1fr']}
                      as="form"
                      onSubmit={handleSubmit}
                      noValidate
                    >
                      <Box>
                        <Field
                          name="email"
                          validate={(value) => {
                            if (!value) {
                              return 'Enter an email address';
                            }
                            if (!isEmail(value)) {
                              return 'Enter a valid email address';
                            }
                          }}
                        >
                          {({ input, meta }) => (
                            <Input
                              {...input}
                              label="Email address"
                              error={meta.touched && meta.error}
                              type="text"
                            />
                          )}
                        </Field>
                      </Box>
                      <Box>
                        <Field
                          name="number"
                          validate={(value) => {
                            if (!value) {
                              return 'Enter a number';
                            }

                            if (value && !isValidNumber(value)) {
                              return 'Enter a valid number';
                            }
                          }}
                        >
                          {({ input, meta }) => (
                            <>
                              <Label
                                mb={2}
                                id={input.name}
                                htmlFor={input.name}
                              >
                                Contact number
                              </Label>
                              <PhoneInput
                                {...input}
                                labelId={input.name}
                                invalid={meta.touched && meta.error}
                              />
                              {meta.error && meta.touched && (
                                <FormError>{meta.error}</FormError>
                              )}
                            </>
                          )}
                        </Field>
                      </Box>
                      <Box gridColumn="1 / -1">
                        <Field
                          name="body"
                          validate={(value) => {
                            if (!value) {
                              return 'Enter a message body';
                            }
                            if (value.length < 10) {
                              return 'Your message is too short';
                            }
                          }}
                        >
                          {({ input, meta }) => (
                            <>
                              <Textarea
                                {...input}
                                label="Your message"
                                invalid={meta.touched && meta.error}
                                sx={{
                                  flexGrow: 1,
                                }}
                              />
                              {meta.error && meta.touched && (
                                <FormError>{meta.error}</FormError>
                              )}
                            </>
                          )}
                        </Field>
                      </Box>
                      <Flex justifyContent="flex-end">
                        <Button
                          // size="large"
                          color="brand1"
                          type="submit"
                          loading={submitting}
                          disabled={submitting}
                          iconRight={faEnvelope}
                          id="send-support-message"
                        >
                          <FormattedMessage
                            id="send"
                            defaultMessage="Send message"
                          />
                        </Button>
                      </Flex>
                    </Grid>
                  )}
                </Form>
              </Flex>
            ) : (
              <Flex alignItems="center" justifyContent="center">
                <Box style={{ textAlign: 'center' }}>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    size="5x"
                    style={{ marginTop: '1rem' }}
                    className="success"
                  />
                  <Box as="p" fontWeight="heading">
                    Sent
                  </Box>
                  <p>Thanks, we'll be in touch.</p>
                </Box>
              </Flex>
            )}
          </Grid>
        </GridContainer>
      </Panel>
    </>
  );
};

export default ContactForm;
