import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Image from 'next/legacy/image';
import React from 'react';
import { Box, Container, Flex, Grid } from 'shared/grid';
import RenderSlate from 'shared/slate-shared/render';
// import dynamic from 'next/dynamic';
import { Heading } from 'shared/typography';
import { unType } from 'shared/utils/cms';
import {
  ImageEmbed,
  nextImageProps,
  Panel,
  SectionSubtitle,
  SectionTagline,
  VideoEmbed,
} from '../landing-shared';

const elementOverrides = {
  'heading-one': function headingOne(element, children) {
    return (
      <Heading fontSize={5} as="h1" mb="1em">
        {children}
      </Heading>
    );
  },
  'heading-two': function headingTwo(element, children) {
    return (
      <Heading fontSize={4} as="h2" mb="1em">
        {children}
      </Heading>
    );
  },
  'heading-three': function headingThree(element, children) {
    return (
      <Heading fontSize={3} as="h3" mb="1em">
        {children}
      </Heading>
    );
  },
  'list-item': function listItem(element, children) {
    return (
      <Box
        as="li"
        // ml="1em"
        sx={{
          listStyle: 'none',
          marginLeft: 0,
        }}
      >
        <Box color="success" display="inline-block" mr={2}>
          <FontAwesomeIcon icon={faCheck} />
        </Box>{' '}
        {children}
      </Box>
    );
  },
  'bulleted-list': function bulletedList(element, children) {
    return (
      <Box
        as="ul"
        mb="1em"
        // ml="1em"
        ml={0}
      >
        {children}
      </Box>
    );
  },
  'numbered-list': function numberedList(element, children) {
    return (
      <Box as="ol" mb="1em">
        {children}
      </Box>
    );
  },
  // paragraph: function p(element, children) {
  //   return (
  //     <Box as="p" fontSize={3} mb={3}>
  //       {children}
  //     </Box>
  //   );
  // },
  image: function image(element, children) {
    return (
      <Flex justifyContent="center">
        <Box
          width={['100%', `${element.width ?? 100}%`]}
          maxWidth={`min(${element.naturalWidth}px, 100%)`}
          sx={{
            borderRadius: 3,
            boxShadow: 'rgb(16 25 40 / 8%) 0px 16px 32px 0px',
          }}
          mb="1em"
        >
          <Image
            lazyBoundary="600px"
            sizes="550px"
            layout="responsive"
            alt="cta"
            {...nextImageProps(element)}
          />
        </Box>
      </Flex>
    );
  },
};

// const LottieEmbed = ({ lottieData }) => {
//   return (
//     <Box
//       position="relative"
//       // sx={{ aspectRatio: `${media.lottieData.w} / ${media.lottieData.h}` }}
//     >
//       <Box
//         sx={{
//           width: '100%',
//           paddingBottom: `${(lottieData.h / lottieData.w) * 100}%`,
//         }}
//       ></Box>
//       <Box position="absolute" sx={{ top: 0 }}>
//         {/* <img src="/images/animation.svg" /> */}
//         <Lottie play loop animationData={lottieData} />
//       </Box>
//     </Box>
//   );
// };

const MediaContentSection = ({ data, ...rest }) => {
  const {
    title,
    subtitle,
    tagline,
    sectionId,
    layout,
    mediaType,
    videoEmbed,
    image,
    body,
  } = React.useMemo(() => unType(data), [data]);

  return (
    <Panel {...rest} sectionId={sectionId}>
      <Container px={3}>
        <Grid
          gridTemplateColumns={['1fr', '1fr 1fr']}
          gridGap={4}
          gridTemplateAreas={[
            `
          'panel-text'
          'panel-image'  
          `,
            layout === 'textRight'
              ? `
            'panel-image panel-text'
          `
              : `
          'panel-text panel-image'
        `,
          ]}
        >
          <Flex
            gridArea="panel-text"
            alignItems="center"
            pr={layout === 'textRight' ? 0 : [0, 5]}
            pl={layout !== 'textRight' ? 0 : [0, 5]}
          >
            <Grid>
              {tagline && (
                <SectionTagline textAlign={null} mx={null}>
                  {tagline}
                </SectionTagline>
              )}
              <Heading fontSize={[4, 6]} fontWeight="bold">
                {title}
              </Heading>
              {subtitle && (
                <SectionSubtitle textAlign={null} mx={null}>
                  {subtitle}
                </SectionSubtitle>
              )}

              <Box fontSize={3}>
                <RenderSlate value={body} elementOverrides={elementOverrides} />
              </Box>
            </Grid>
          </Flex>
          <Flex gridArea="panel-image" my={-3} alignItems="center">
            <Box flexBasis="100%">
              {mediaType === 'image' && (
                <ImageEmbed value={image} sizes="550px" />
              )}

              {mediaType === 'videoEmbed' && (
                <VideoEmbed
                  value={videoEmbed}
                  sizes="550px"
                  boxShadow="large"
                />
              )}
            </Box>
          </Flex>
        </Grid>
      </Container>
    </Panel>
  );
};

export default MediaContentSection;
