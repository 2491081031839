import { Theme, ThemeContext } from '@emotion/react';
import Image from 'next/legacy/image';
import React from 'react';
import Carousel from 'shared/carousel';
import { Box, Flex, Grid } from 'shared/grid';
import { unType } from 'shared/utils/cms';
import {
  GridContainer,
  nextImageProps,
  Panel,
  Paragraphs,
  SectionHeading,
  SectionSubtitle,
  SectionTagline,
} from '../landing-shared';

const Testimonials = ({ data, ...rest }) => {
  const { title, subtitle, tagline, items, sectionId } = React.useMemo(
    () => unType(data),
    [data]
  );
  const theme = React.useContext(ThemeContext) as Theme;

  return (
    <Panel {...rest} sectionId={sectionId}>
      <GridContainer py={3} gridGap={[3, 4]}>
        <Grid>
          {tagline && <SectionTagline>{tagline}</SectionTagline>}
          <SectionHeading>{title}</SectionHeading>
          {subtitle && <SectionSubtitle>{subtitle}</SectionSubtitle>}
        </Grid>

        <Box
          height={['450px', '450px', '400px']}
          borderRadius={2}
          sx={{
            overflow: 'hidden',
            width: '100%',
          }}
        >
          <Carousel
            overflow="visible"
            options={{
              arrowColor: theme.colors.gray[3],
              auto: false,
              dotColor: theme.colors.primary,
              duration: 500,
              interval: 2000,
              loop: false,
              threshold: 100,
            }}
          >
            {items
              .filter((v) => !!v.businessName)
              .map(({ logo, businessName, quote, attribution, url }, idx) => (
                <Grid
                  py={[5, 5]}
                  px={[4, 5]}
                  key={idx}
                  sx={{
                    height: '100%',
                    overflow: 'hidden',
                  }}
                  gridTemplateColumns={logo?.url ? ['1fr', '1fr 1fr'] : '1fr'}
                >
                  <Flex
                    justifyContent={
                      logo?.url ? ['center', 'flex-start'] : 'center'
                    }
                    alignItems="center"
                  >
                    <Box
                      as="blockquote"
                      fontSize={
                        quote.length > 100
                          ? [3, 4 + (logo?.url ? 0 : 1)]
                          : [4, 5 + (logo?.url ? 0 : 1)]
                      }
                      textAlign={logo?.url ? ['center', 'left'] : 'center'}
                      fontFamily="heading"
                      position="relative"
                      maxWidth="700px"
                      sx={{
                        position: 'relative',
                      }}
                    >
                      <Box
                        sx={{
                          width: '100%',
                          color: 'brand0.7',
                          fontFamily: 'sans-serif',
                          fontSize: '120px',
                          lineHeight: 0,
                          position: 'absolute',
                          top: 0,
                          zIndex: 0,
                        }}
                      >
                        “
                      </Box>
                      <Paragraphs lineHeight={1.3} text={quote} />
                      <Box
                        fontSize={[2, 3]}
                        as="cite"
                        mt={[2, 3]}
                        sx={{
                          fontStyle: 'normal',
                        }}
                      >
                        <Box as={url ? 'a' : 'span'} href={url} target="_blank">
                          {attribution && (
                            <>
                              <Box as="span" fontWeight="heading">
                                {attribution}
                              </Box>
                              ,{' '}
                            </>
                          )}
                          {businessName}
                        </Box>
                      </Box>
                    </Box>
                  </Flex>

                  {logo?.url && (
                    <Flex
                      sx={{
                        // overflow: 'hidden',
                        display: ['none !important', 'flex !important'],
                      }}
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Box
                        as={url ? 'a' : 'div'}
                        href={url}
                        target="_blank"
                        sx={{ maxHeight: '100%', flexBasis: '100%' }}
                      >
                        <Image
                          lazyBoundary="600px"
                          sizes="50vw"
                          layout="responsive"
                          objectFit="contain"
                          {...nextImageProps(logo)}
                          width={500}
                          height={300}
                          title={businessName}
                          alt={businessName}
                        />
                      </Box>
                    </Flex>
                  )}
                </Grid>
              ))}
          </Carousel>
        </Box>
      </GridContainer>
    </Panel>
  );
};

export default Testimonials;
