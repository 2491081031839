import settings from '../../settings';
import axios from '../../anaxios';
import {
  SMS_PRICING_FETCH_REQUESTED,
  SMS_PRICING_FETCH_SUCCEEDED,
  SMS_PRICING_FETCH_FAILED,
  SMS_PACKAGES_FETCH_REQUESTED,
  SMS_PACKAGES_FETCH_SUCCEEDED,
  SMS_PACKAGES_FETCH_FAILED,
} from './action-types';

export const fetchSmsPricing = () => (dispatch) => {
  dispatch({ type: SMS_PRICING_FETCH_REQUESTED });

  axios
    .get(`${settings.api2Root}/sms-pricing`)
    .then(({ data }) => {
      dispatch({ type: SMS_PRICING_FETCH_SUCCEEDED, payload: data });
    })
    .catch(() => dispatch({ type: SMS_PRICING_FETCH_FAILED }));
};

export const fetchPackages = () => (dispatch, getState) => {
  dispatch({ type: SMS_PACKAGES_FETCH_REQUESTED });

  const state = getState();
  const token = state.session.token;
  const businessId = state.userPreferences.currentBusinessId;

  return axios
    .get(`${settings.api2Root}/packages`, {
      params: { business_id: businessId },
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({ type: SMS_PACKAGES_FETCH_SUCCEEDED, payload: data });
    })
    .catch(() => dispatch({ type: SMS_PACKAGES_FETCH_FAILED }));
};

export const instantTopUp = (tokens) => (dispatch, getState) => {
  const state = getState();
  const token = state.session.token;
  const businessId = state.userPreferences.currentBusinessId;

  return axios.post(
    `${settings.api2Root}/payfast-topup`,
    { business_id: businessId, tokens },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  // .then(({ data }) => {
  //   dispatch({ type: SMS_PACKAGES_FETCH_SUCCEEDED + 'lol', payload: data });
  // })
};
