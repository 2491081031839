import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import Image from 'next/legacy/image';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from 'shared/button';
import { Box, Flex, Grid } from 'shared/grid';
import { openSignUpModal } from 'shared/redux/globals/actions';
import { unType } from 'shared/utils/cms';
import { extractVideoUrl } from 'shared/utils/video';
import { useAppDispatch } from '../../store';
import {
  GridContainer,
  nextImageProps,
  Panel,
  Paragraphs,
  SectionHeading,
} from '../landing-shared';

const ImageCTA = ({ data, ...rest }) => {
  const {
    title,
    description,
    videoUrl,
    image,
    ctaButtonText,
    layout,
    sectionId,
  } = React.useMemo(() => unType(data), [data]);
  const dispatch = useAppDispatch();

  return (
    <Panel {...rest} sectionId={sectionId}>
      <GridContainer
        py={0}
        gridTemplateColumns={['1fr', '1fr 1fr']}
        gridTemplateAreas={[
          `
          'panel-text'
          'panel-image'  
          `,
          layout === 'imageLeft'
            ? `
            'panel-image panel-text'
          `
            : `
          'panel-text panel-image'
        `,
        ]}
      >
        <Grid
          gridGap={4}
          py={5}
          pr={layout === 'imageLeft' ? 0 : [0, 5]}
          pl={layout !== 'imageLeft' ? 0 : [0, 5]}
          gridArea="panel-text"
          textAlign={['center', 'left']}
        >
          <SectionHeading textAlign={null} mx={null}>
            {title}
          </SectionHeading>
          <Paragraphs text={description} fontSize={[2, 3]} />

          {ctaButtonText && (
            <Flex justifyContent={['center', 'left']}>
              <Box>
                <Button
                  color="brand1"
                  size="large"
                  className="try-it"
                  onClick={() => dispatch(openSignUpModal())}
                  iconRight={faArrowRight}
                >
                  {ctaButtonText || (
                    <FormattedMessage
                      id="LandingPage.tryIt"
                      defaultMessage="Get started"
                    />
                  )}
                </Button>
              </Box>
            </Flex>
          )}
        </Grid>

        <Flex
          justifyContent="center"
          flexDirection="column"
          position="relative"
          mx={layout === 'imageLeft' ? 0 : -3}
        >
          {videoUrl && (
            <Box
              as="iframe"
              src={extractVideoUrl(videoUrl)}
              frameBorder={0}
              //@ts-ignore
              allow="autoplay; fullscreen"
              allowFullscreen={true}
              autoPlay={true}
              sx={{
                display: 'block',
                pointerEvents: 'all',
                borderRadius: 3,
                width: '100%',
                height: '56.25vw',
                maxHeight: 'calc(1068px / 16 * 9)',
                '@supports(aspect-ratio: 16 / 9)': {
                  aspectRatio: '16 / 9',
                  height: 'unset',
                  maxHeight: 'unset',
                },
                boxShadow: 'rgb(16 25 40 / 8%) 0px 16px 32px 0px',
              }}
            />
          )}

          {!videoUrl && image && (
            <Box
              sx={{
                mx: layout === 'imageLeft' ? undefined : 3,
                gridArea: 'panel-image',
              }}
            >
              <Image
                lazyBoundary="600px"
                sizes="550px"
                layout="responsive"
                loading={
                  image.mimeType === 'image/svg+xml' ? 'lazy' : undefined
                }
                {...nextImageProps(image)}
                alt="cta"
              />
            </Box>
          )}
        </Flex>
      </GridContainer>
    </Panel>
  );
};

export default ImageCTA;
