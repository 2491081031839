import { Theme, ThemeContext } from '@emotion/react';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { themeGet } from '@styled-system/theme-get';
import Color from 'color';
import Image from 'next/legacy/image';
import NextLink from 'next/link';
import React from 'react';
import { useMediaPredicate as useMedia } from 'react-media-hook';
import { useHasMounted } from 'shared/client-only';
import { Box, Flex, Grid } from 'shared/grid';
import useComponentSize from 'shared/hooks/component-size';
import { Heading, Link } from 'shared/typography';
import { unType } from 'shared/utils/cms';
import {
  GridContainer,
  nextImageProps,
  Panel,
  Paragraphs,
  SectionHeading,
  SectionSubtitle,
  SectionTagline,
} from '../landing-shared';

const Feature = ({
  isLast,
  title,
  image,
  blurb,
  page,
  isOpen: isOpen_,
  setOpen,
}) => {
  const contentRef = React.useRef(null);
  const { height } = useComponentSize(contentRef);
  const hasMounted = useHasMounted();

  // Ensures we show google everything
  const contentHeight = !hasMounted ? 9999 : height;
  const isOpen = !hasMounted || isOpen_;
  const theme = React.useContext<Theme>(ThemeContext as any);

  return (
    <Box
      // borderColor={isOpen ? 'brand0.4' : 'gray.1'}
      borderColor={
        isOpen
          ? 'brand0.4'
          : Color(themeGet(`colors.gray.8`)({ theme })).alpha(0.1).string()
      }
      borderWidth={[0, '4px']}
      borderLeftStyle={['none', 'solid']}
      // borderBottomStyle={['solid', 'none']}
      px={[0, 4]}
      mx={[0, -3]}
    >
      <Flex
        width="100%"
        as="button"
        type="button"
        justifyContent="space-between"
        alignItems="center"
        onClick={(e) => {
          setOpen(isOpen);
        }}
        py={3}
        // borderTopColor={openIdx === idx - 1 ? 'gray.2' : undefined}
        // borderTopWidth={openIdx === idx - 1 ? '1px' : undefined}
        // borderTopStyle={openIdx === idx - 1 ? 'solid' : undefined}
        // borderBottomColor={!isOpen && !isLast ? 'gray.2' : undefined}
        // borderBottomWidth={!isOpen && !isLast ? '1px' : undefined}
        // borderBottomStyle={!isOpen && !isLast ? 'solid' : undefined}
        // bg={isOpen ? undefined : 'rgba(197, 36, 184, 0.5)'}

        color={isOpen ? 'brand0.8' : 'gray.7'}
      >
        <Heading
          fontSize={[2, 3]}
          fontWeight={500}
          sx={{
            // overflow: 'hidden',
            // display: '-webkit-box',
            // WebkitLineClamp: 1,
            // WebkitBoxOrient: 'vertical',
            overflowWrap: 'break-word',
            textAlign: 'left',
            maxWidth: ['calc(100% - 48px)', 'calc(100% - 48px)'],
          }}
        >
          {title}
        </Heading>

        <Box
          display={['block', 'none']}
          sx={{
            transition: 'transform 200ms ease-in-out',
            transitionDelay: isOpen ? undefined : '200ms',
            transform: isOpen ? 'rotate(90deg)' : 'rotate(-90deg)',
          }}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </Box>
      </Flex>

      <Box
        sx={{
          transition: 'max-height 200ms ease-in-out, opacity 200ms ease-in-out',
          maxHeight: isOpen ? contentHeight : 0,
          opacity: isOpen ? 1 : 0,
          overflow: 'hidden',
          maxWidth: [undefined, 'calc(100% - 32px)'],
        }}
      >
        <Grid pt={0} pb={3} ref={contentRef}>
          <Box display={['block', 'none']}>
            {image?.url && (
              <Box px={4}>
                <Image
                  // priority
                  lazyBoundary="600px"
                  loading={
                    image.mimeType === 'image/svg+xml' ? 'lazy' : undefined
                  }
                  {...nextImageProps(image)}
                  title=""
                  alt=""
                  objectFit="contain"
                  layout="responsive"
                />
              </Box>
            )}
          </Box>

          <Paragraphs text={blurb} />

          {page?.slug && (
            <Box>
              <Link
                as={NextLink}
                href={`/${page.slug === 'home' ? '' : page.slug}`}
                display="inline-block"
              >
                Read more...
              </Link>
            </Box>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

const FeatureSection = ({ data, ...rest }) => {
  const {
    title,
    subtitle: st,
    tagline,
    description,
    items,
    sectionId,
    layout,
  } = React.useMemo(() => unType(data), [data]);

  const theme = React.useContext<Theme>(ThemeContext as any);
  const isMobile = useMedia(theme.mediaQueries.smallOnly);

  const subtitle = st || description;
  const [openIdxs, setOpenIdxs] = React.useState([0]);

  React.useEffect(() => {
    if (!isMobile) {
      setOpenIdxs((v) => [v[0] || 0]);
    }
  }, [isMobile]);

  return (
    <Panel {...rest} sectionId={sectionId}>
      <GridContainer>
        <Grid>
          {tagline && <SectionTagline>{tagline}</SectionTagline>}
          <SectionHeading>{title}</SectionHeading>
          {subtitle && <SectionSubtitle>{subtitle}</SectionSubtitle>}
        </Grid>

        <Grid
          gridTemplateColumns={['1fr', '1fr 1fr']}
          gridGap={4}
          gridTemplateAreas={[
            `
          'panel-text'
          'panel-image'  
          `,
            layout === 'textRight'
              ? `
            'panel-image panel-text'
          `
              : `
          'panel-text panel-image'
        `,
          ]}
        >
          <Box
            gridArea="panel-text"
            sx={
              layout === 'textLeft'
                ? {
                    pl: [0, 3],
                  }
                : {}
            }
          >
            {items.map(({ image, title, blurb, page }, idx) => (
              <Feature
                key={idx}
                isLast={items.length - 1 === idx}
                setOpen={(open) =>
                  setOpenIdxs((idxs) => {
                    if (open) {
                      if (!isMobile && idxs.length === 1) {
                        return idxs;
                      }

                      return idxs.filter((v) => v !== idx);
                    } else {
                      if (isMobile) {
                        return [idx, ...idxs];
                      } else {
                        return [idx];
                      }
                    }
                  })
                }
                isOpen={openIdxs.includes(idx)}
                title={title}
                image={image}
                blurb={blurb}
                page={page}
              />
            ))}
          </Box>
          <Box
            display={['none', 'block']}
            position="relative"
            gridArea="panel-image"
          >
            <Box paddingBottom="100%" className="spacer" />
            {items.map(({ image: itemImage, title, text, page }, idx) => {
              const image = itemImage?.url ? itemImage : items[0].image;
              return (
                <Box
                  key={idx}
                  width="100%"
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    transition:
                      itemImage?.url && itemImage?.url !== items[0].image?.url
                        ? 'opacity 100ms linear'
                        : null,
                    opacity: openIdxs[0] === idx ? '1' : '0',
                  }}
                  px={3}
                >
                  {image?.url && (
                    <Box
                      as={NextLink}
                      href={`/${page?.slug === 'home' ? '' : page?.slug || ''}`}
                    >
                      <Image
                        lazyBoundary="600px"
                        loading={
                          image.mimeType === 'image/svg+xml'
                            ? 'lazy'
                            : undefined
                        }
                        {...nextImageProps(image)}
                        title=""
                        alt=""
                        objectFit="fill"
                        layout="responsive"
                        sizes="50vw"
                      />
                    </Box>
                  )}
                </Box>
              );
            })}
          </Box>
        </Grid>
      </GridContainer>
    </Panel>
  );
};

export default FeatureSection;
