import { getCDNImageUrl } from 'landing-next/components/landing-shared';
import { GetServerSideProps } from 'next';
import { NextSeo } from 'next-seo';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import React from 'react';
import axios from 'shared/anaxios';
import { Box } from 'shared/grid';
import {
  deleteUndefined,
  filterEntry,
  getInEntry,
  replaceReferences,
} from 'shared/utils/cms';
import Footer from '../components/footer';
import Header from '../components/header';
import Layout from '../components/landing-layout';
import ContactForm from '../components/sections/contact-form';
import FaqSection from '../components/sections/faq';
import FeatureSection from '../components/sections/feature';
import FeaturedBusinessSection from '../components/sections/featured-business';
import HomeHero from '../components/sections/home-hero';
import IconSummarySection from '../components/sections/icon-summary';
import ImageCtaSection from '../components/sections/image-cta';
import MediaContentSection from '../components/sections/media-content';
import PricingSection from '../components/sections/pricing';
import RichTextSection from '../components/sections/richtext';
import TestimonialSection from '../components/sections/testimonial';
import TextCtaSection from '../components/sections/text-cta';

const compoonentMap = {
  homePageHero: HomeHero,
  featuredBusinessSection: FeaturedBusinessSection,
  featureSection: FeatureSection,
  textCtaSection: TextCtaSection,
  imageCtaSection: ImageCtaSection,
  testimonialSection: TestimonialSection,
  pricingSection: PricingSection,
  faqSection: FaqSection,
  contactForm: ContactForm,
  iconSummarySection: IconSummarySection,
  richTextSection: RichTextSection,
  mediaContentSection: MediaContentSection,
};

const { serverRuntimeConfig } = getConfig();

const EMPTY_ARRAY = [];

const itemToLinkData = (v, language, sectionIds, currentPath) => {
  if (v.type === 'pageLink') {
    const slug = getInEntry('page.slug', v, language);
    const href = `/${slug === 'home' ? '' : slug}`;

    if (href == currentPath) {
      return null;
    }

    const text = getInEntry('text', v, language);
    return { type: v.type, href, text };
  } else if (v.type === 'textLink') {
    const href = getInEntry('url', v, language);
    const text = getInEntry('text', v, language);

    if (href[0] === '#' && !sectionIds.includes(href.substring(1))) {
      return null;
    }

    return { type: v.type, href, text };
  } else if (v.type === 'linkGroup') {
    return {
      type: v.type,
      text: getInEntry('title', v, language),
      links: getInEntry('items', v, language)
        .map((v) => itemToLinkData(v, language, sectionIds, currentPath))
        .filter(Boolean),
    };
  }
};

export const getLinkList = (
  path,
  pageData,
  settingsData,
  language,
  sectionIds = [],
  currentPath = null
) => {
  const defaultLinks = getInEntry(path, settingsData, language);
  const pageLinks = getInEntry(path, pageData, language);

  return (pageLinks || defaultLinks || EMPTY_ARRAY)
    .map((v) => itemToLinkData(v, language, sectionIds, currentPath))
    .filter(Boolean);
};

const IndexPage = ({ pageData, settingsData }) => {
  const { locale: language, asPath } = useRouter();

  const content = React.useMemo(
    () => getInEntry('translations.content', pageData, language),
    [language, pageData]
  );

  const sectionIds = React.useMemo(
    () => content.map((v) => getInEntry('sectionId', v)).filter(Boolean),
    [content]
  );

  const seoImage = React.useMemo(
    () =>
      getInEntry('translations.image', pageData, language) ||
      getInEntry('translations.image', settingsData, language),
    [language, pageData, settingsData]
  );

  const headerLinks = React.useMemo(
    () =>
      getLinkList(
        'translations.headerLinks',
        pageData,
        settingsData,
        language,
        sectionIds,
        asPath
      ),
    [asPath, language, pageData, sectionIds, settingsData]
  );
  const footerLinks = React.useMemo(
    () =>
      getLinkList(
        'translations.footerSections',
        pageData,
        settingsData,
        language,
        sectionIds,
        asPath
      ),
    [asPath, language, pageData, sectionIds, settingsData]
  );

  const topBg = React.useMemo(
    () =>
      getInEntry(
        'translations.content.0.background',
        pageData,
        language,
        false
      ),
    [language, pageData]
  );

  return (
    <Layout>
      <NextSeo
        title={getInEntry('translations.title', pageData, language)}
        titleTemplate={`Bookem | %s`}
        description={getInEntry('translations.description', pageData, language)}
        // canonical={config.url && `${config.url}/${slug}`}
        // canonical={'https://bookem.com'}
        openGraph={{
          description: getInEntry(
            'translations.description',
            pageData,
            language
          ),
          images: seoImage?.url
            ? [
                {
                  url: getCDNImageUrl(seoImage?.url),
                  width: seoImage.nativeWidth,
                  height: seoImage.nativeHeight,
                },
              ]
            : [],
        }}
        // noindex={disallowRobots}
      />
      <Header
        isHome={asPath === '/'}
        links={headerLinks}
        settingsData={settingsData}
        topBg={topBg}
      />
      <Box>
        {content.map((item, idx, arr) => {
          const Component = compoonentMap[item.type];

          const prev = arr[idx - 1] || {};
          const next = arr[idx + 1] || {};

          const isLast = idx === arr.length - 1;
          const isFirst = idx === 0;

          const prevBackground = getInEntry('background', prev, null, false);
          const prevDecoration = getInEntry('decoration', prev, null, false);
          const background = getInEntry('background', item, null, false);
          const decoration = getInEntry('decoration', item, null, true);
          const nextBackground = getInEntry('background', next, null, false);
          const nextDecorator = getInEntry('decoration', next, null, false);

          if (Component) {
            return (
              <Component
                key={idx}
                data={item}
                settingsData={settingsData}
                {...{
                  prevBackground,
                  prevDecoration,
                  background,
                  decoration,
                  nextBackground,
                  nextDecorator,
                }}
                isLast={isLast}
                isFirst={isFirst}
                index={idx}
              />
            );
          } else {
            return null;
          }
        })}
      </Box>
      <Footer isHome={true} links={footerLinks} settingsData={settingsData} />
    </Layout>
  );
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  globalThis['LOCALE'] =
    (context.req.headers['accept-language'] || '').split(',')[0] || 'en';
  const slug = Array.isArray(context.query.slug)
    ? context.query.slug[0]
    : context.query.slug || 'home';

  const ip = context.req.headers['x-forwarded-for'];

  // TODO: optimise this
  const [geoip, cmsRes, redirectsRes, systemPlans] = await Promise.all([
    axios
      .get(`http://freegeoip:8080/json/${ip}`)
      .then((res) => res.data)
      .catch((error) => {
        console.log({ headers: context.req.headers, ip });
        console.log({ error });
        return {
          city: 'Cape Town',
          country_code: 'ZA',
          country_name: 'South Africa',
          latitude: -33.9161,
          longitude: 18.4139,
          metro_code: 0,
          region_code: 'WC',
          region_name: 'Western Cape',
          status: 'done',
          time_zone: 'Africa/Johannesburg',
          zip_code: 8000,
        };
      }),
    axios.get(`${serverRuntimeConfig.API2_ROOT}/api2/cms-entries`),
    axios.get(`${serverRuntimeConfig.API2_ROOT}/api2/site-redirects`),
    axios.get(`${serverRuntimeConfig.API2_ROOT}/api2/system-plans`),
  ]);

  const redirect = redirectsRes.data.items.find(
    (v) => v.src.toLowerCase() === slug.toLowerCase()
  );

  if (redirect) {
    return {
      redirect: {
        destination: redirect.dest,
        permanent: false,
      },
    };
  }

  const entryMap = cmsRes.data.reduce((agg, cur) => {
    if (cur.type == 'page') {
      agg[cur.id] = filterEntry(cur, ['slug', 'translations.title']);
    } else {
      agg[cur.id] = cur;
    }

    return agg;
  }, {});

  let settingsData = cmsRes.data.find((v) => v.type === 'settings') || {};
  let pageData = cmsRes.data.find((v) => v.value?.slug?.value === slug);

  if (!pageData) {
    return {
      notFound: true,
    };
  }

  settingsData = replaceReferences(settingsData, entryMap);
  pageData = replaceReferences(pageData, entryMap);

  return {
    props: deleteUndefined({
      initialReduxState: {
        geoip: geoip,
        plans: {
          status: 'done',
          data: systemPlans.data.reduce((agg, cur) => {
            agg[cur.id] = cur;
            return agg;
          }, {}),
        },
      },
      pageData,
      settingsData,
    }),
  };
};

export default IndexPage;
