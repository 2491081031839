import Image from 'next/legacy/image';
import React from 'react';
import { Box, Flex } from 'shared/grid';
import { unType } from 'shared/utils/cms';
import {
  GridContainer,
  nextImageProps,
  Panel,
  SectionHeading,
} from '../landing-shared';

const FeaturedBusinessSection = ({ data, ...rest }) => {
  const { title, items, background, decoration, sectionId } = unType(data);

  return (
    <Panel {...rest} sectionId={sectionId}>
      <GridContainer gridGap={4}>
        <Flex justifyContent="center">
          <SectionHeading fontSize={[4, 6]} maxWidth="700px">
            {title}
          </SectionHeading>
        </Flex>

        <Flex alignItems="center" justifyContent="center" flexWrap="wrap">
          {items.map(({ logo, name }, idx) => (
            <Box
              display="inline-block"
              key={idx}
              sx={{
                flexShrink: 0,
                flexBasis: ['50%', '25%'],
                filter: 'grayscale(1) contrast(1)',
                '&:hover': {
                  filter: 'grayscale(0)',
                },
              }}
              px={4}
              py={4}
            >
              <Box maxHeight="80px">
                <Image
                  lazyBoundary="600px"
                  {...nextImageProps(logo)}
                  height={50}
                  width={100}
                  title=""
                  alt=""
                  objectFit="contain"
                  layout="responsive"
                  sizes="25vw"
                />
              </Box>
            </Box>
          ))}
        </Flex>
      </GridContainer>
    </Panel>
  );
};

export default FeaturedBusinessSection;
